import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const Pooles = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me cook recipes from Meathead Godlwyn's cookbook, Meathead, a collection of recipes and techniques for grilling and barbecueing.";

  return (
    <Layout>
      <Seo title="Meathead" image={image} meta={metaData} description={description}/>
      <h1>Meathead</h1>
      <h2>The Science of Great Barbecue and Grilling</h2>
      <p><a href="https://amazingribs.com/about-us/meet-meathead/">Meathead Goldwyn</a> is the
      publisher of <a href="https://amazingribs.com">Amazing Ribs</a>, a go-to site for recipes
      and advice about both barbecueing and grilling (they are different!), and a member of  
      the <a href="https://www.americanroyal.com/bbq/barbecue-hall-of-fame/">Barbecue Hall of 
      Fame</a>. He judges not only barbecue competitions, but also wines! He has a very diverse
      career, and you should visit the first link on this page to read more about him.</p>
      <p>My favorite tidbit is this: his nickname was originally a reference to the iconic 
      television sitcom, Archie Bunker, but it is certainly apropos given his chosen profession.</p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/meathead/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default Pooles;
